<template>
	<li class="nav-item dropdown" :class="{ show }" v-if="isLogin" v-clickaway="clickawayClose">
		<a href="#" class="navbar-nav-link dropdown-toggle caret-0" @click.prevent="openPopup">
			<i class="icon icon-bubble"></i>
			<span class="d-md-none ml-2">Сообщения</span>
			<span class="badge badge-pill bg-warning-400 ml-auto ml-md-0" v-if="messages.length">{{ messages.length }}</span>
		</a>

		<div class="dropdown-menu dropdown-menu-right dropdown-content wmin-md-350" :class="{ show }">
			<div class="dropdown-content-header">
				<span class="font-weight-semibold">Сообщения</span>
				<!-- <a href="#" class="text-default">
					<i class="icon-compose"></i>
				</a>-->
			</div>

			<div class="dropdown-scrollable" v-if="messages.length">
				<ul class="media-list">
					<template v-for="(message, i) of messages" :key="message.id">
						<li class="dropdown-item" v-if="i < 5">
							<div class="mr-2 position-relative" v-if="message.author">
								<img :src="avatar(message)" width="36" height="36" class="rounded-circle" />
							</div>

							<div class="media-body">
								<p class="mb-0">
									<router-link :to="message.route" v-if="message.route">
										<span class="text-muted">{{ message.message }}</span>
									</router-link>
									<span class="text-muted" v-else>{{ message.message }}</span>
								</p>
								<p class="mb-0">
									<router-link :to="`/user/${message.author}`" v-if="message.author">
										<span class="font-weight-semibold">{{ message.authorname }}</span>
									</router-link>
									<span class="text-muted float-right font-size-sm">{{ timeago(message.updated_at) }}</span>
								</p>
							</div>
						</li>
					</template>
				</ul>
			</div>

			<div class="dropdown-content-body" v-else>
				<span>У Вас нету новых сообщений</span>
			</div>

			<div class="dropdown-content-footer justify-content-center p-0">
				<router-link class="bg-light text-grey w-100 py-2" title="Все сообщения" to="/messages"><i class="icon icon-menu d-block text-center"></i> </router-link>
			</div>
		</div>
	</li>
</template>

<script>
import { computed, defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';

import { clickaway } from "@/core/mixins";
import { timeago } from '@/core/helpers/utils';

import { config } from "@/config";

export default defineComponent({
	mixins: [clickaway],

	methods: {
		clickawayClose(event) {
			this.clickaway_close = false;

			const path = event.path || (event.composedPath ? event.composedPath() : undefined);

			if (path) {
				for (const item of path) {
					if (item?.classList?.contains('dropdown-item') || item?.classList?.contains('dropdown-content-footer')) this.closePopup();
				}
			}
		}
	},

	setup() {
		const { state, dispatch } = useStore();

		const avatar = (message) => `${config.server}/api/images/avatar/${message.avatar}`;

		onMounted(() => {
			dispatch('messagesRead', 20);
		})

		return {
			avatar,
			isLogin: computed(() => state.token && state.user.exp),
			messages: computed(() => state.messages),
			timeago
		}
	}
})
</script>
