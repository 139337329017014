import api from "@/core/api/api";

export const info = (params: any) => api.query(`/pgi/info`, { data: params, method: "post" });
export const setStatus = (id: string, params: any) => api.query(`/pgi/setstatus/${id}`, { data: params, method: "post" }, false);
export const registered = (data: any) => api.query(`/pgi/registered`, { data, method: "post" });
export const getResearch = (researchs: string) => api.query(`/pgi/getresearch`, { data: researchs, method: "post" });
export const lastBiopsyNmb = (data: any) => api.query(`/pgi/lastbiopsynmb`, { data, method: "post" }, false);
export const researchComplete = (id: string) => api.query(`/pgi/researchcomplete/${id}`, { method: "post" });
export const getNumeratorData = (data: any) => api.query(`/pgi/getnumeratordata`, { data, method: "post" });
export const checkBeforeCreating = (data: any) => api.query(`/pgi/checkbeforecreating`, { data, method: "post" });
export const barcodeStatus = (barcode: string, status: number) => api.query(`/pgi/barcodestatus/${barcode}/${status}`, { method: "get" }, false);
